import * as React from "react"
import { Header, RecipeOpen, Footer, BottomNavbar } from "../components/index"
import { Helmet } from "react-helmet"

import mockup from "../utils/data"

export default function mealPost({ pageContext, location }) {
  const {
    slug,
    title,
    titleRu,
    calories,
    carbs,
    fat,
    protein,
    mealTime,
    recipe,
    steps,
    img,
    multiplier,
  } = pageContext

  const getServings = () => {
    // let numb = location?.search?.substring(1, location?.search?.length)
    let numb = location?.search?.replace("?", "")

    if (numb) {
      let toNumb = Number(numb)

      return multiplier === 1 ? Math.round(toNumb) : toNumb
    } else return 1
  }

  return (
    <div>
      <Helmet>
        <title>Esteekey - Recipe Opened</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="pt-[69px] xl:pt-0">
        <RecipeOpen
          title={titleRu}
          overview={mockup.recipe_post.overview}
          url={mockup.recipe_post.url}
          ingredients={recipe?.ingredients || []}
          image={img}
          content={mockup.recipe_post.content}
          steps={steps || []}
          calories={calories}
          carbs={carbs}
          fat={fat}
          protein={protein}
          mealTime={mealTime}
          servings={getServings()}
        />
        <BottomNavbar />
        <Footer />
      </div>
    </div>
  )
}
